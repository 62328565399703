import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/:id",
    name: "Cutaway",
    component: () => import("../views/Cutaway.vue"),
    meta: {
      layout: "Default",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.fullPath === "/") {
    window.location.href = "https://nazmiev.club/";
  }

  next();
});

export default router;
