
import { defineComponent, defineAsyncComponent } from "vue";

export default defineComponent({
  components: {
    Default: defineAsyncComponent(() => import("@/layout/DefaultLayout.vue")),
  },
  computed: {
    layout() {
      return this.$route.meta.layout;
    },
  },
});
